/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sim-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 4.5a.5.5 0 01.5-.5h2v2H5zM8.5 6V4h2a.5.5 0 01.5.5V6zM5 7h6v2H5zm3.5 3H11v1.5a.5.5 0 01-.5.5h-2zm-1 0v2h-2a.5.5 0 01-.5-.5V10z"/><path pid="1" d="M3.5 0A1.5 1.5 0 002 1.5v13A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5V3.414a1.5 1.5 0 00-.44-1.06L11.647.439A1.5 1.5 0 0010.586 0zm2 3h5A1.5 1.5 0 0112 4.5v7a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 11.5v-7A1.5 1.5 0 015.5 3"/>',
    },
});
